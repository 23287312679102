import * as React from 'react';
import '../assets/css/main.scss';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import Welcome from '../components/Welcome/Welcome';

export default () => (
  <Layout>
    <SEO title="Home" />
    <Welcome />
  </Layout>
);
