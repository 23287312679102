import React from 'react';
import styled from 'styled-components';
import {
  Container,
  ProfileImage,
  Subtitle,
  TextContainer,
  Title,
} from './Welcome.styled';

const Big = styled('span')`
  font-size: inherit;
`;

const Medium = styled('span')`
  font-size: 30px;
`;

const Small = styled('span')`
  font-size: 20px;
`;

export default function Welcome() {
  return (
    <Container>
      <ProfileImage alt="profile_image" src={'profile.jpg'} />
      <TextContainer>
        <Title>Hello,</Title>
        <Subtitle>
          <Big>
            I am Ognen,
            <br /> a frontend developer,
          </Big>
          <br />
          <Medium>an environmentalist,</Medium>
          <br />
          <Small>an ENTJ</Small>
        </Subtitle>
      </TextContainer>
    </Container>
  );
}
