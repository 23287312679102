import styled from 'styled-components';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled('h2')`
  color: white;
  font-size: 30px;
  margin: 0;

  ${props => props.theme.mediaSmallDevice} {
    font-size: 18px;
  }

  ${props => props.theme.mediaMediumDevice} {
    font-size: 18px;
  }
`;

export const Subtitle = styled('h1')`
  color: white;
  font-size: 40px;
  margin: 0;

  ${props => props.theme.mediaSmallDevice} {
    font-size: 24px;
  }

  ${props => props.theme.mediaMediumDevice} {
    font-size: 24px;
  }
`;

export const TextContainer = styled('div')``;

export const ProfileImage = styled('img')`
  border-radius: 50%;
  width: 250px;
  height: 250px;
  align-self: center;
  margin-bottom: 100px;
  -webkit-box-shadow: 0px 0px 54px -7px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 54px -7px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 54px -7px rgba(255, 255, 255, 1);
`;
